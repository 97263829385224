
import React from "react";

export default function Recovery (){

    return (
        <div className="recovery">
           <div className="container" >
               <div className="content" >
                   <h2><span>Data</span>Recovery</h2>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et fermentum dui. Ut orci quam, ornare sed lorem sed, hendrerit auctor dolor. Nulla viverra, nibh quis ultrices malesuada, ligula ipsum vulputate diam, aliquam egestas nibh ante vel dui. Sed in.</p>
                   <div>
                   <button>Learn More</button>
                   </div>
               </div>
           </div>
        </div>
    )
}