

import React from "react";
import Recovery from "../comp/Recovery";
import Footer from "../comp/Footer";
import Navbar from "../comp/Navbar";

export default function RecoveryPage (){
    return (
        <>
            <Navbar/>
            <Recovery/>
            <Footer/>
        </>
    )
}